<template>
    <div>
        <wxpay-ing :data="data" v-if="payStatus == 1"  @changeEven="changeEven"></wxpay-ing>
        <pay-success :data="data" v-if="payStatus == 2"></pay-success>
        <pay-error  :data="data" v-if="payStatus == 3"></pay-error>
    </div>
</template>

<script>

import wxpayIng from "@/components/pay/WxPayIng.vue";
import paySuccess from "@/components/pay/PaySuccess.vue";
import payError from "@/components/pay/PayError.vue";
export default {
    name: "weChat",
    components: {
        wxpayIng,
        paySuccess,
        payError
    },
    data(){
        return{
            data:{},
            payStatus:0, //1支付中2已支付3错误
        }
    },
    mounted: function () {
        this.wxPubPay();
    },
    methods:{
        wxPubPay(){
            var that = this;
            let code = this.$route.query.code;
            let sign = this.$route.query.state;
            that.newApi.wxPubPay({code:code,sign:sign}).then((res) =>{
                if(res.isSuccess == 1){
                    that.data = res.data;
                    if(res.data.code == 200){
                        that.payStatus = 1;
                    }else if(res.data.code == 4003){
                        that.payStatus = 2;
                    }else{
                        that.payStatus = 3;
                    }
                }
            })
        },
        changeEven(data){
            var that = this;
            that.data = data;
            that.payStatus = 2;
        }
    }
}
</script>

<style scoped>
*{
    margin: 0px;
    padding: 0px;
}
.payment-page {
    padding: 20px;
    text-align: center;
}
.header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.icon {
    font-size: 24px;
    margin-right: 8px;
}
.title {
    font-size: 18px;
    font-weight: bold;
}
.payment-info {
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 8px;
}
.info-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.instructions {
    margin-bottom: 20px;
    text-align: left;
    font-size: 14px;
}
.complete-button {
    width: 100%;
    margin-bottom: 10px;
    line-height: 30px;
}
.retry-button {
    width: 100%;
    line-height: 30px;
}


</style>