<template>
    <div class="payment-success">
        <el-card class="box-card" style="padding-bottom: 0px;">
            <div slot="header" class="clearfix">
                <el-row>
                    <el-col :span="12">订单错误</el-col>
                    <el-col :span="12" class="align-right">
                        <i style="color: #F56C6C;" class="el-icon-error"></i>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">错误原因</el-col>
                    <el-col :span="12" class="align-right">{{data.msg}}</el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">错误代码</el-col>
                    <el-col :span="12" class="align-right">{{data.code}}</el-col>
                </el-row>
            </div>
            <div style="width: 100%;text-align: center;margin-top: 15px;color: #409EFF;cursor: pointer;" @click="closeWin">关闭页面</div>
        </el-card>

    </div>
</template>

<script>
export default {
    name: 'PayError',
    props:{
        data:{
            type:Object,
            defaults() {
                return {}
            }
        }
    },
    data(){
        return{
        }
    },
    mounted: function () {

    },
    methods:{
        closeWin() {
            var that = this;
            // 在这里添加倒计时结束后的操作
            if(that.isWeChat()){
                // 调用微信关闭当前页面的接口
                WeixinJSBridge.invoke('closeWindow');
            }else if(that.isAlipay()){
                // 调用支付宝关闭当前页面的接口
                AlipayJSBridge.call("closeWebview");
            }else{
                that.utils.msg("页面打开错误");
            }
            return;
        },
        isWeChat() {
            var ua = window.navigator.userAgent.toLowerCase();
            return /MicroMessenger/i.test(ua);
        },
        isAlipay() {
            var ua = window.navigator.userAgent.toLowerCase();
            return /Alipay/i.test(ua);
        }
    }
}
</script>

<style>
.payment-success {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Align items to the top */
}

.box-card {
    width: 300px;
    padding: 20px;
}

.align-right {
    text-align: right;
    line-height: 25px;
}
</style>
