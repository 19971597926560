<template>
    <div class="payment-page">
        <div class="header">
            <i class="el-icon-loading"></i>
            <span class="title">支付中...</span>
        </div>
        <el-card class="payment-info">
            <div class="info-row">
                <span>产品名称</span>
                <span>{{data.payInfo.proutName}}</span>
            </div>
            <div class="info-row">
                <span>购买数量</span>
                <span>1</span>
            </div>
            <div class="info-row">
                <span>实际付款</span>
                <span>¥{{data.payInfo.payAmt}}</span>
            </div>
        </el-card>
        <div class="instructions">
            <p>1. 如已支付成功，请点击“已完成支付”</p>
            <p>2. 如未支付成功，请点击“重新支付”</p>
        </div>
        <el-button type="primary" class="complete-button" @click="clickComplete">已完成支付</el-button>
        <el-button style="margin-left: 0px;" class="retry-button" @click="clickRetry">重新支付</el-button>
    </div>
</template>

<script>

export default {
    name: "weChat",
    data(){
        return{

        }
    },
    props:{
        data:{
            type:Object,
            default() {
                return {}
            }
        }
    },
    mounted: function () {
        this.onBridgeReady();
    },
    methods:{
        clickComplete(){
            var that = this;
            let searchParams = new URLSearchParams(window.location.search);
            let sign = searchParams.get('state');
            that.newApi.queryOrderInfo({sign:sign}).then((res) =>{
                if(res.isSuccess == 1){
                    that.$emit("changeEven",res.data);
                }
            })
        },
        clickRetry(){
            window.history.back();
        },

        onBridgeReady(){
            var that= this;
            WeixinJSBridge.invoke(
                'getBrandWCPayRequest', that.data.payInfo.pay_info,
                function(res) {
                    // 支付成功
                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                        // 使用以上方式判断前端返回,微信团队郑重提示：
                        //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                    }
                    // 支付过程中用户取消
                    if (res.err_msg == "get_brand_wcpay_request:cancel") {

                        console.log(res)
                    }
                    // 支付失败
                    if (res.err_msg == "get_brand_wcpay_request:fail") {

                        console.log(res)
                    }
                    /**
                     * 其它
                     * 1、请检查预支付会话标识prepay_id是否已失效
                     * 2、请求的appid与下单接口的appid是否一致
                     * */
                    if (res.err_msg == "调用支付JSAPI缺少参数：total_fee") {

                        console.log(res)
                    }
                });
        }
    }
}
</script>

<style scoped>
*{
    margin: 0px;
    padding: 0px;
}
.payment-page {
    padding: 20px;
    text-align: center;
}
.header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.icon {
    font-size: 24px;
    margin-right: 8px;
}
.title {
    font-size: 18px;
    font-weight: bold;
}
.payment-info {
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 8px;
}
.info-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.instructions {
    margin-bottom: 20px;
    text-align: left;
    font-size: 14px;
}
.complete-button {
    width: 100%;
    margin-bottom: 10px;
    line-height: 30px;
}
.retry-button {
    width: 100%;
    line-height: 30px;
}


</style>